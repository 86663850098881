<template>
  <div>
    <v-container grid-list-lg>
      <h2 class="blue-grey--text nunito lighten-3"><b>Subscription</b></h2>
      <v-divider class="mb-2"></v-divider>
      <div class="mt-8 px-3">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Billplz Collection"
              outlined
              color="info"
              :value="settings.billplz_collection"
              @input="updateInput($event, 'billplz_collection')"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Billplz Payout"
              outlined
              color="info"
              :value="settings.billplz_payout"
              @input="updateInput($event, 'billplz_payout')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Billplz Token"
              outlined
              color="info"
              :value="settings.billplz_token"
              @input="updateInput($event, 'billplz_token')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Ejen2u Billplz Collection"
              outlined
              color="info"
              :value="settings.ejen2u_billplz_collection"
              @input="updateInput($event, 'ejen2u_billplz_collection')"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Ejen2u Billplz Credit Collection"
              outlined
              color="info"
              :value="settings.ejen2u_billplz_credit_collection"
              @input="updateInput($event, 'ejen2u_billplz_credit_collection')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Ejen2u Billplz Token"
              outlined
              color="info"
              :value="settings.ejen2u_billplz_token"
              @input="updateInput($event, 'ejen2u_billplz_token')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <h3 class="blue-grey--text nunito lighten-3 mb-2">
              <b>Sandbox</b>
            </h3>
            <v-btn-toggle
              class="transparent"
              :value="settings.sandbox"
              @input="updateInput($event, 'sandbox')"
            >
              <v-btn :value="true">
                <span class="hidden-sm-and-down">True</span>
              </v-btn>

              <v-btn :value="false">
                <span class="hidden-sm-and-down">False</span>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Trial days"
              outlined
              color="info"
              :value="settings.trial"
              @input="updateInput($event, 'trial')"
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="flex justify-end mt-5">
          <v-btn color="success" @click="save">save</v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Settings",
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      settings: "getGlobalSettings",
    }),
  },

  methods: {
    updateInput(e, index) {
      this.settings[index] = e;
      this.$store.commit("updateGlobalSettings", this.merchant_detail);
    },

    save() {
      this.$store.dispatch("startLoad");
      this.$store.dispatch("saveGlobalSettings");
    },

    // firstHalf(i) {
    //   const half = Math.ceil(i.length / 2);
    //   return i.splice(0, half);
    // },

    // secondHalf(i) {
    //   const half = Math.ceil(i.length / 2);
    //   return i.splice(-half);
    // },
  },

  beforeMount() {
    this.$store.dispatch("startLoad");
    // this.$store.dispatch("fetchGlobal");
  },
};
</script>
<style >
.v-text-field__details {
  display: none;
}
</style>